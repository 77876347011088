import { useState } from "react"
import { useSelector } from "react-redux"
import classNames from "classnames"

import Navbar from "../Navbar"
import Footer from "../footer/Footer"

import styles from "./Layout.module.scss"

export default function Layout({ children, props }) {
  const [reppysDismissed, setReppysDismissed] = useState(true)

  const { showReppys } = props

  const { currentUser } = useSelector(state => state.auth)
  const { days_until_next_rating, status } = currentUser
  const accessExpired =
    days_until_next_rating === 0 && status !== "new_to_sales"

  const reppysActive = showReppys && reppysDismissed === false

  return (
    <>
      <Navbar
        navbarBackground={props?.navbarBackground}
        solidNavbarMobile={props?.solidNavbarMobile}
        showReppys={showReppys}
        reppysDismissed={reppysDismissed}
        setReppysDismissed={setReppysDismissed}
      />
      <main>
        <div
          className={classNames({
            [styles.buffer__oneBanner]: accessExpired || reppysActive,
            [styles.buffer__twoBanners]: accessExpired && reppysActive === true,
          })}
        />
        {children}
      </main>
      <Footer />
    </>
  )
}

export const getLayout = (page, props) => <Layout props={props}>{page}</Layout>

"use client"
import cn from "classnames"
import { useValidateMobile } from "hooks/validateMobile"

import LocalImage from "components/shared/LocalImage"
import Container from "react-bootstrap/Container"

import headerImage from "../../../../public/header-home.avif"

import styles from "./header.module.scss"

const Header = ({
  children,
  hasBackground = true,
  hasContainer,
  isDown = false,
  className,
  fluidContainer = "lg",
  baseStyles = false,
  bootstrapClasses,
  lockedOut,
}) => {
  const { isMobile } = useValidateMobile()

  return (
    <header
      className={cn(styles.header, {
        [styles.header__background]: hasBackground,
        [styles.header__down]: isDown,
        [styles.header__background__lockedOut]: lockedOut,
        [className]: className,
      })}
      id="layout-header"
    >
      {hasBackground && (
        <picture className={styles.header__image}>
          <LocalImage alt="RepVue" src={headerImage} priority={!isMobile} />
        </picture>
      )}

      {hasContainer ? (
        <>{children}</>
      ) : (
        <Container
          fluid={fluidContainer}
          className={cn("position-relative", {
            [bootstrapClasses]: bootstrapClasses,
          })}
        >
          <div className={cn({ [styles.header__base]: baseStyles })}>
            {children}
          </div>
        </Container>
      )}
    </header>
  )
}

export default Header

import { useEffect, useRef, useState } from "react"

const useVisibleElement = (options, allowReset) => {
  const [visibleElements, setVisibleElements] = useState({})
  const targetRefs = useRef({})

  const setRef = id => el => {
    if (el) {
      el.dataset.id = id
      targetRefs.current[id] = el
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setVisibleElements(prev => ({
            ...prev,
            [entry.target.dataset.id]: entry.isIntersecting,
          }))
          observer.unobserve(entry.target)
        }
        if (allowReset && visibleElements[entry.target.dataset.id]) {
          setVisibleElements(prev => ({
            ...prev,
            [entry.target.dataset.id]: false,
          }))
        }
      })
    }, options)

    Object.values(targetRefs.current).forEach(ref => {
      if (ref) observer.observe(ref)
    })

    return () => {
      Object.values(targetRefs.current).forEach(ref => {
        if (ref) observer.unobserve(ref)
      })
    }
  }, [options, allowReset])

  return [setRef, visibleElements]
}

export default useVisibleElement

import classNames from "classnames"

import Link from "next/link"

import styles from "./LinkList.module.scss"

const DesktopList = ({ nav, mobile }) => (
  <div className={classNames(styles.pages, { [styles.desktop]: !mobile })}>
    {nav.map(option => {
      const { header, links } = option

      return (
        <div key={header} className={styles.section}>
          <span>{header}</span>
          <ul key={nav.indexOf(option)} className={styles.nav}>
            {links.map(link => (
              <li
                key={link.title}
                className={classNames({
                  [styles.desktop__link]: !mobile,
                  [styles.pages__link]: mobile,
                })}
              >
                <Link href={`/${link.value}`}>{link.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      )
    })}
  </div>
)

export default DesktopList

import dayjs from "dayjs"

import Head from "next/head"

const Meta = ({
  title,
  description,
  url,
  twitterTitle,
  imageAlt,
  featuredImage,
  noCanonical,
  children,
  noIndex,
}) => {
  const metaImage = `${process.env.NEXT_PUBLIC_URL}/repvue-og.png`
  return (
    <Head>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="description" content={description} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=5"
      />
      <meta name="theme-color" content="#002541" />
      <meta
        name="robots"
        content={`follow, ${noIndex ? "noindex" : "index"}`}
      />
      {!noCanonical && <link rel="canonical" href={url} />}

      <meta property="og:image" content={featuredImage || metaImage} />
      <meta
        property="og:image:secure_url"
        content={featuredImage || metaImage}
      />
      <meta property="og:locale" content="en_US"></meta>
      <meta property="og:image:width" content="1024" />
      <meta property="og:image:height" content="645" />
      <meta
        property="og:image:alt"
        content={imageAlt || "Tech Sales Salaries, Ratings, and Jobs"}
      />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:site_name" content="RepVue.com" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content="RepVue" />
      <meta
        property="og:updated_time"
        content={dayjs().startOf("day").toISOString()}
      />
      <meta property="og:url" content={url} />
      <meta property="og:description" content={description} />

      <meta property="twitter:image" content={featuredImage || metaImage} />
      <meta name="twitter:image:alt" content={imageAlt || "RepVue Logo"} />
      <meta name="twitter:title" content={twitterTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@RepVue" />
      <meta name="twitter:creator" content="@RepVue" />

      {children}
    </Head>
  )
}

export default Meta

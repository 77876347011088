import LocalImage from "components/shared/LocalImage"
import Link from "next/link"
import { Container, Row, Col } from "react-bootstrap"
import LinkList from "./LinkList"
import Socials from "components/shared/socials"

import repvue from "assets/repvue-dark.png"

import styles from "./Footer.module.scss"

const FOOTER_NAV = [
  {
    header: "About",
    links: [
      { title: "About Us", value: "about" },
      { title: "Blog", value: "blog" },
      { title: "FAQs", value: "faq" },
      { title: "Community Guidelines", value: "community-guidelines" },
      { title: "Contact Us", value: "contact" },
      { title: "Help Center", value: "help" },
    ],
  },
  {
    header: "Product",
    links: [
      { title: "Companies", value: "companies" },
      { title: "Sales Organizations", value: "sales-organizations" },
      {
        title: "Compare Companies",
        value: "companies/compare/Salesforce-vs-Snowflake",
      },
      {
        title: "Quarterly Report",
        value: "cloud-index/2024/Q2",
      },
      { title: "Rankings", value: "rankings" },
      { title: "Salaries", value: "salaries" },
      { title: "Sales Jobs", value: "sales-jobs" },
      { title: "Salary Calculator", value: "salary-calculator" },
      { title: "For Employers", value: "employers" },
      { title: "Community Insights", value: "community-insights" },
    ],
  },
  {
    header: "Awards",
    links: [
      { title: "Best Sales Organization Awards", value: "awards" },
      {
        title: "Top Overall Sales Org",
        value: "awards/winners/2024/spring/overall",
      },
      {
        title: "Top Rated Professional Development Companies",
        value: "awards/winners/2024/spring/professional-development",
      },
      {
        title: "Top Rated Culture & Leadership Companies",
        value: "awards/winners/2024/spring/culture-and-leadership",
      },
      {
        title: "Top Rated Product-Market Fit Companies",
        value: "awards/winners/2024/spring/product-market-fit",
      },
      {
        title: "Top Rated Incentive Comp Structure Companies",
        value: "awards/winners/2024/spring/incentive-comp-structure",
      },
      {
        title: "Top Rated Inbound Lead / Opportunity Flow Companies",
        value: "awards/winners/2024/spring/inbound-lead",
      },
      {
        title: "Top Rated Diversity & Inclusion Companies",
        value: "awards/winners/2024/spring/diversity-and-inclusion",
      },
    ],
  },
]

const Footer = () => (
  <footer className={styles.footer}>
    <Container fluid="lg">
      <Row className="h-100">
        <Col
          xxl={{ offset: 1, span: 5 }}
          lg={{ offset: 1, span: 3 }}
          md={{ offset: 1, span: 4 }}
          className="pt-0 pb-lg-0 pb-1 d-flex"
        >
          <div className={styles.companyInfo}>
            <Link href="/">
              <div className={styles.wrapper}>
                <LocalImage alt="RepVue" src={repvue} />
              </div>
            </Link>
            <div className={styles.mobile}>
              <LinkList nav={FOOTER_NAV} mobile />
            </div>
            <Socials />
            <ul className={styles.legal}>
              <li>
                <Link href="/terms" className={styles.legal__link}>
                  Terms
                </Link>
              </li>
              <li>
                <span className={styles.dot}>&#183;</span>
              </li>
              <li>
                <Link href="/privacy" className={styles.legal__link}>
                  Privacy
                </Link>
              </li>
              <li>
                <span className={styles.dot}>&#183;</span>
              </li>
              <li>
                <Link href="/copyright-policy" className={styles.legal__link}>
                  Copyright Policy
                </Link>
              </li>
            </ul>
            <span className={styles.copyright}>
              &#169; {new Date().getFullYear()} All Rights Reserved
            </span>
          </div>
        </Col>
        <Col
          md={13}
          xxl={11}
          className="d-none d-md-flex justify-content-lg-center my-2 my-lg-0 pb-lg-3"
        >
          <LinkList nav={FOOTER_NAV} />
        </Col>
      </Row>
    </Container>
  </footer>
)

export default Footer
